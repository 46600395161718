export enum Locale {
  Sv = 'sv',
  En = 'en',
}

export function localizeSquidexField(locale?: Locale) {
  return function (field: { sv: string; en?: string }) {
    if (!field) {
      return ''
    }
    if (!locale) {
      return field[Locale.Sv]
    }

    return field[locale] || field[Locale.Sv]
  }
}
