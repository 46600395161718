import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div`
  color: #fff;
`

export const BgContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.grayDark};
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  pointer-events: none;

  video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%;
  }
`

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }
`

export const ImageWrapper = styled.div`
  flex: 1 1 auto;

  @media ${device.laptop} {
    width: 50%;
  }

  img {
    width: 80%;
    max-width: 47.0625rem;
  }
`

export const TextWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;

  @media ${device.laptop} {
    width: 50%;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  align-self: flex-end;
  padding-left: 3.125rem;
  padding-bottom: 3.125rem;
`

export const PuffContainer = styled.div`
  position: absolute;
  top: 4.375rem;
  right: 0.9375rem;
  width: 7.5rem;
  z-index: -1;

  @media ${device.mobile} {
    top: 5.3125rem;
  }

  @media ${device.tablet} {
    width: 12.5rem;
  }

  @media ${device.laptop} {
    top: 6.5625rem;
    right: 1.875rem;
  }

  @media ${device.laptopXL} {
    width: 100%;
    max-width: 60rem;
    top: -0.9375rem;
    left: 0;
  }

  img {
    width: 6.25rem;

    @media ${device.mobile} {
      width: 7.5rem;
    }

    @media ${device.laptop} {
      width: 9.375rem;
    }

    @media ${device.laptopXL} {
      position: absolute;
      right: 1.25rem;
      top: 0;
      width: 12.5rem;
    }
  }
`

export const Logo = styled.div`
  width: 100%;

  @media ${device.laptopXL} {
    position: relative;
  }

  & > img {
    width: 100%;
    max-width: 68.75rem;
    transform: translate(-8%, 13%);
  }
`

export const Subtitle = styled.div`
  max-width: 68.75rem;
`
