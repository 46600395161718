import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { localizeSquidexField } from 'src/utils/localization'

export default function Meta({ pageContext, data }) {
  const l = localizeSquidexField(pageContext.locale)

  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            querySiteContents {
              data {
                metaTitle {
                  en
                  sv
                }
                metaDescription {
                  en
                  sv
                }
                metaImage {
                  iv {
                    url
                    imageFile {
                      childImageSharp {
                        fixed(width: 600) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const title = data.cms.querySiteContents[0]?.data?.metaTitle
        const description = data.cms.querySiteContents[0]?.data?.metaDescription

        const image = data.cms.querySiteContents[0]?.data?.metaImage?.iv

        let imageUrl

        if (image && image.length > 0) {
          imageUrl = image[0].imageFile?.childImageSharp?.fixed?.src
        }

        const lang =
          pageContext.locale === 'sv' || pageContext.locale === 'en'
            ? pageContext.locale
            : 'sv'

        return (
          <Helmet>
            <html
              lang={lang}
              data-site-version={pageContext.version.site}
              data-cms-version={pageContext.version.cms}
            />
            <title>{l(title)}</title>
            <meta name="description" content={l(description)} />
            <meta name="og:description" content={l(description)} />
            {imageUrl && <meta name="og:image" content={imageUrl} />}
          </Helmet>
        )
      }}
    />
  )
}
