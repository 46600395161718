import React, { useState, useEffect } from 'react'
import { Wrapper, Container } from './style'
import LogoImg from 'src/images/logo.png'

export default function Nav() {
  const [sticky, setSticky] = useState<boolean>(false)
  const [hidden, setHidden] = useState<boolean>(false)

  let lastScroll = 0

  function handleScroll() {
    const scroll = window.pageYOffset

    if (scroll === lastScroll) {
      return
    }

    const isScrollingDown = scroll > lastScroll

    if (scroll > 0) {
      setSticky(true)
    } else {
      setSticky(false)
    }

    if (scroll > 60 && isScrollingDown) {
      setHidden(true)
    } else {
      setHidden(false)
    }

    lastScroll = scroll
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Wrapper sticky={sticky} hide={hidden}>
      <Container id="nav-container">
        <img src={LogoImg} alt="Logo" />
      </Container>
    </Wrapper>
  )
}
