import styled from 'styled-components'
import { device } from 'src/styles/device'

type WrapperProps = {
  sticky: boolean
  hide: boolean
}

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 5rem;
  top: 0;
  left: 0;
  transition: transform 0.25s, background-color 1s;
  transform: translateY(0rem);
  color: #fff;

  @media ${device.laptop} {
    height: 6.25rem;
  }

  img {
    height: 4.6875rem;
    transform: translate(-0.9375rem, 0.125rem);

    @media ${device.laptop} {
      height: 7.1875rem;
      transform: translate(-0.9375rem, -0.5rem);
    }
  }
`

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;

  @media ${device.laptop} {
    height: 6.25rem;
  }

  ${({ sticky, theme }) =>
    sticky &&
    `
    ${Container} {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }
  `}

  ${({ hide }) =>
    hide &&
    `
    ${Container} {
      transform: translateY(-100%);
      background-color: transparent;
    }
  `}
`
