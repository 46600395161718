import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div`
  padding: 6.5625rem 0;
  text-align: center;
  background-color: ${({ theme }) => theme.color.grayDarker};
  color: #fff;
`
export const ContainerWide = styled.div`
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  margin-bottom: 0.75rem;
`

export const Container = styled.div`
  position: relative;
  max-width: 37.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.625rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  text-align: left;
`

export const Field = styled.div`
  margin-bottom: 1.875rem;
`

type CheckboxFieldTextProps = {
  expanded: boolean
  largeText: boolean
}

export const CheckboxFieldText = styled.div<CheckboxFieldTextProps>`
  margin-top: -1.25rem;
  padding-left: 2.25rem;

  p {
    font-size: 0.875rem;
  }

  ${({ expanded }) =>
    !expanded &&
    `
    p:first-child {
      max-width: 100%;
      padding-bottom: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p:not(:first-child) {
      display: none;
    }
  `}

  p:first-child:last-child {
    padding-bottom: 0;
  }

  ${({ largeText }) =>
    largeText &&
    `

    margin-top: -1.6rem;

    p {
      font-size: 1.25rem;
    }
  `}
`

export enum CheckboxFieldSize {
  Large = 'Large',
  Default = 'Default',
}

type CheckboxFieldProps = {
  size?: CheckboxFieldSize
}

export const CheckboxField = styled(Field)<CheckboxFieldProps>`
  position: relative;
  padding: 1.875rem 1.875rem 1.875rem;
  background-color: ${({ theme }) => theme.color.grayDark};
  border-radius: 0.3125rem;
  text-align: left;

  input[type='checkbox'] {
    transform: scale(1.5);
  }

  ${({ size }) => {
    return (
      size === CheckboxFieldSize.Large &&
      `
      padding-left: 2.5rem;

      input[type='checkbox'] {
        position: absolute;
        top: 50%;
        transform: scale(3) translateX(-3px) translateY(-50%) translateY(4px);
      }

      input[type='checkbox'] + ${CheckboxFieldText} {
        margin-top: 0;
      }
    `
    )
  }}
`

export const ExpandTermsButton = styled.div`
  padding-left: 2.25rem;

  button {
    font-size: 0.6875rem;
  }
`

type SubmitButtonContainerProps = {
  submitted: boolean
}

export const SubmitButtonContainer = styled.div<SubmitButtonContainerProps>`
  position: relative;
  margin-bottom: 1.5625rem;

  button {
    transition: opacity .25s;
  }

  h4 {
    pointer-events: none;
    width: 100%;
    position: absolute;
    top: 0.9375rem;
    left: 0;
    opacity: 0;
    transition: transform .25s, opacity: .25s;
    transition-delay: .25s;
    transform: scale(0.5);
    text-align: center;
  }

  ${({ submitted }) =>
    submitted &&
    `
    button {
      pointer-events: none;
      opacity: 0 !important;
    }

    h4 {
      pointer-events: all;
      opacity: 1;
      transform: scale(1);
    }
  `}
`

type ErrorProps = {
  hasError: boolean
}

export const Error = styled.div<ErrorProps>`
  color: red;
  max-height: 0;
  margin-bottom: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.25s, margin-bottom 0.25s, opacity 0.25s;

  ${({ hasError }) =>
    hasError &&
    `
    margin-bottom: 1.5rem;
    max-height: 18.75rem;
    opacity: 1;
  `}
`

export const AnchorLink = styled.div`
  a.button {
    position: absolute;
    top: 1rem;
    right: 0.9375rem;
    padding: 0.875rem 1.25rem;
    font-size: 1rem;

    @media ${device.laptop} {
      top: 1.5625rem;
      right: 1.875rem;
    }
  }
`
