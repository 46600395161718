import React from 'react'
import { Reset, Global, Input } from './style'
import { ThemeProvider } from 'styled-components'
import theme from 'src/styles/theme'

export default function Page({ children }) {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Reset />
        <Global />
        <Input />
        {children}
      </ThemeProvider>
    </React.Fragment>
  )
}
