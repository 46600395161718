import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
  background-color: transparent;
  color: #fff;
  overflow-x: hidden;

  @media ${device.laptop} {
    padding-top: 16.25rem;
    padding-bottom: 16.25rem;
  }
`

type ContainerProps = {
  layout: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: 79.25rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;

  @media ${device.laptop} {
    width: auto;
    flex-direction: row;
    align-items: center;
  }

  ${({ layout }) => {
    return (
      layout === 'ImageRight' &&
      `
      flex-direction: column;

      @media ${device.laptop} {
        flex-direction: row-reverse;
      }

      ${ImageContainer} {
        text-align: right;
      }
    `
    )
  }}
`

export const TextContainer = styled.div`
  flex: 1 1 auto;
  padding: 2.5rem;

  @media ${device.laptop} {
    width: 50%;
  }

  h2:last-child {
    padding-bottom: 0;
  }

  a[target='_blank'] {
    display: inline-block;
    margin-top: 1.5625rem;
    text-align: center;
  }
`

export const ImageContainer = styled(TextContainer)`
  img {
    width: 100%;

    @media ${device.laptop} {
      max-width: 32.8125rem;
    }
  }
`
