import { createGlobalStyle } from 'styled-components'
import { device } from 'src/styles/device'
import {
  Wrapper as PostWrapper,
  TextContainer as PostTextContainer,
} from 'src/components/cms/Post/style'
import { Wrapper as ContactFormWrapper } from 'src/components/cms/ContactForm/style'
import { Wrapper as FooterWrapper } from 'src/components/common/Footer/style'
import QuicksandSemiBold from 'src/fonts/Quicksand-SemiBold.ttf'
import OpenSans from 'src/fonts/OpenSans-Regular.ttf'
import { darken } from 'polished'

export const Reset = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
    	margin: 0;
    	padding: 0;
    	border: 0;
    	font-size: 100%;
    	font: inherit;
    	vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
    	display: block;
    }
    body {
    	line-height: 1;
    }
    ol, ul {
    	list-style: none;
    }
    blockquote, q {
    	quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
    	content: '';
    	content: none;
    }
    table {
    	border-collapse: collapse;
    	border-spacing: 0;
    }

    html {
        background-color: #000;
    }
`

export const Global = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }

    h1, h2 {
        font-family: ${({ theme }) => theme.font.title};
        font-weight: 700;
        line-height: 1.25;
        color: ${({ theme }) => theme.color.primary};
        letter-spacing: 0.03125rem;
    }

    h1, h2, p {
        padding-bottom: 2.25rem;
    }

    h1 {
        font-size: 3.125rem;
        letter-spacing: 0.625rem;
        text-shadow: ${({ theme }) => theme.color.primaryLight} 0 0 3.75rem;

        @media ${device.laptop} {
            font-size: 6.25rem;
        }
    }

    h2 {
        font-size: 1.6875rem;

        @media ${device.laptop} {
            font-size: 2.25rem;
        }
    }

    h3, h4, h5, span.h5, p, input, textarea {
        font-family: ${({ theme }) => theme.font.body};
        font-weight: 400;
        line-height: 1.5;
    }

    h3 {
        font-size: 1.6875rem;
        font-weight: 700;

        @media ${device.laptop} {
            font-size: 2.25rem;
        }
    }

    h4 {
        font-size: 1.125rem;
        font-weight: 600;

        @media ${device.laptop} {
            font-size: 1.5rem;
        }
    }

    p {
        font-weight: 400;
        font-size: 1.125rem;

        @media ${device.laptop} {
            font-size: 1.3125rem;
        }
    }

    strong {
        font-weight: 600;
    }

    ${PostWrapper} + ${PostWrapper} {
        padding-top: 0;
    }

    ${ContactFormWrapper} + ${FooterWrapper} {
        padding-top: 0;
    }

    a {
        font-family: ${({ theme }) => theme.font.title};
        color: ${({ theme }) => theme.color.primary};
        text-decoration: underline;
        transition: color 0.25s;

        &:hover {
            color: ${({ theme }) => darken(0.25, theme.color.primary)};
        }
    }
`

export const Input = createGlobalStyle`
    label {
        display: block;
        width: 100%;
        color: #fff;
        font-family: ${({ theme }) => theme.font.title};
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 0.625rem;
    }

    input {
        outline-color: ${({ theme }) => theme.color.primary};
    }

    input[type="text"], input[type="email"], textarea {
        display: block;
        width: 100%;
        padding: 1rem 0.9375rem;
        border: 0.0625rem solid ${({ theme }) => theme.color.gray};
        border-radius: 0.3125rem;
        background-color: transparent;
        font-size: 1.25rem;
        color: #fff;
        box-sizing: border-box;

        &:disabled {
            opacity: 0.2;
        }
    }

    button, a.button, ${PostTextContainer} a[target="_blank"] {
        background: ${({ theme }) => theme.button.background};
        padding: 1.25rem 2.5rem;
        color: ${({ theme }) => theme.color.grayDark};
        font-family: ${({ theme }) => theme.font.title};
        font-weight: 700;
        font-size: 1.25rem;
        border-radius: 2.5rem;
        border: none;
        cursor: pointer;
        outline-color: ${({ theme }) => theme.color.primary};
        letter-spacing: 0.03125rem;
        text-decoration: none !important;

        &:disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
`
