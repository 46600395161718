import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div`
  padding-bottom: 5rem;
  padding-bottom: 13.75rem;
  background-color: ${({ theme }) => theme.color.grayDarker};
  text-align: center;
`

export const Logo = styled.div`
  margin-bottom: 0.75rem;

  img {
    width: 15.625rem;
  }
`

export const Text = styled.div`
  margin-bottom: 3rem;
  padding: 0.9375rem;
  font-family: ${({ theme }) => theme.font.title};
  font-size: 1.125rem;
  color: #fff;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }
`

export const Images = styled.div`
  img {
    margin: 0.75rem 0.75rem;
    height: 2.125rem;

    @media ${device.laptop} {
      margin: 0.75rem 1.5625rem;
    }
  }
`
