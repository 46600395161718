export default {
  font: {
    title: 'Quicksand',
    body: 'OpenSans',
    banner: 'OpenSansExtraBold',
  },
  color: {
    primary: '#85FFBD',
    primaryLight: '#B8FEA3',
    primaryAlt: '#82FCBB',
    grayDarker: '#18191c',
    grayDark: '#24262B',
    gray: '#505155',
  },
  button: {
    background:
      'transparent linear-gradient(99deg, #85FFBD 0%, #FFFB7D 100%) 0% 0% no-repeat padding-box',
  },
  shadow: {
    banner: '10px 10px 8px #85FDBD38',
  },
}
