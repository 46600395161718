import React from 'react'
import { FixedObject } from 'gatsby-image'
import {
  Wrapper,
  BgContainer,
  Container,
  ImageWrapper,
  TextWrapper,
  TextContainer,
  Logo,
  PuffContainer,
  Subtitle,
} from './style'
import SmokeVideo from './smoke.mp4'

type FullpageProps = {
  logos: {
    fixed: FixedObject
  }[]
  title: string
  subtitle?: string
  images: {
    fixed: FixedObject
  }[]
  puff?: string
}

export default function Fullpage(props: FullpageProps) {
  return (
    <Wrapper>
      <BgContainer>
        <video autoPlay muted loop playsInline>
          <source src={SmokeVideo} type="video/mp4" />
        </video>
      </BgContainer>
      <Container>
        <ImageWrapper>
          {props.images &&
            props.images.map((fixed: FixedObject, i) => {
              return (
                <img
                  key={i}
                  src={fixed.src}
                  srcSet={fixed.srcSet}
                  alt=""
                  data-aos="fade-right"
                />
              )
            })}
        </ImageWrapper>
        <TextWrapper>
          <TextContainer>
            <Logo>
              {props.puff && (
                <PuffContainer>
                  <img src={props.puff} />
                </PuffContainer>
              )}
              {props.logos &&
                props.logos.map((fixed: FixedObject, i) => {
                  return (
                    <img key={i} src={fixed.src} srcSet={fixed.srcSet} alt="" />
                  )
                })}
            </Logo>
            <Subtitle>
              <h4>{props.subtitle}</h4>
            </Subtitle>
          </TextContainer>
        </TextWrapper>
      </Container>
    </Wrapper>
  )
}
